'use client'
import React, { FC, useState, useCallback, MouseEventHandler } from 'react'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'

import Input from '~/src/components/generic/Input'
import FadeTransition from '~/src/components/generic/FadeTransition'
import Link from '~/src/components/generic/Link'
import CheckIcon from '~/src/components/generic/PhosphorIcons/CheckIcon'
import CircleNotchIcon from '~/src/components/generic/PhosphorIcons/CircleNotchIcon'
import EnvelopeIcon from '~/src/components/generic/PhosphorIcons/EnvelopeIcon'

import { identifyUser, MixpanelEvent, track } from '~/clients/mixpanelClient'
import LoginButton from '~/src/components/LoginPage/Button'
import { getURLWithQueryParams } from '~/src/components/LoginPage/utils'

enum Stage {
  INITIAL = 0,
  MAGIC_LINK_PENDING = 1,
  MAGIC_LINK_SUCCESS = 2,
}

const title = {
  [Stage.INITIAL]: 'Welcome back',
  [Stage.MAGIC_LINK_PENDING]: 'Welcome back',
  [Stage.MAGIC_LINK_SUCCESS]: 'Magic link sent!',
}

const primaryButtonContent = {
  [Stage.INITIAL]: 'Get magic link',
  [Stage.MAGIC_LINK_PENDING]: (
    <span className="animate-spin">
      <CircleNotchIcon size={20} />
    </span>
  ),
  [Stage.MAGIC_LINK_SUCCESS]: <CheckIcon size={20} />,
}

const fadTransitionIndex = {
  [Stage.INITIAL]: 0,
  [Stage.MAGIC_LINK_PENDING]: 0,
  [Stage.MAGIC_LINK_SUCCESS]: 1,
}

const LoginPage: FC = () => {
  const [email, setEmail] = useState('')
  const [currentStage, setCurrentStage] = useState(Stage.INITIAL)
  const [isEmailInputFocused, setIsEmailInputFocused] = useState(false)

  const handleOnSendMagicLinkClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    async (e) => {
      e.preventDefault()
      setCurrentStage(Stage.MAGIC_LINK_PENDING)
      await fetch(
        `${process.env.NEXT_PUBLIC_GENERATE_MAGIC_LINK_URL}?${new URLSearchParams({
          email,
        })}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        }
      )
        .then(async (response) => {
          const data = await response.json()
          if (!response.ok) {
            const error = (data && data.message) || response.status

            return Promise.reject(error)
          }
          setCurrentStage(Stage.MAGIC_LINK_SUCCESS)
          identifyUser(`${data?.user_id}`)
          track(MixpanelEvent.UserLogin, { userId: data?.user_id })
        })
        .catch(() => {
          window.alert('Error generating magic link: Email is invalid.')
          setCurrentStage(Stage.INITIAL)
        })
    },
    [email]
  )

  const handleOnBackToLoginClick = useCallback(() => {
    setCurrentStage(Stage.INITIAL)
  }, [])

  const handleOnSignInWithGoogleClick = useCallback(() => {
    location.href = getURLWithQueryParams(process.env.NEXT_PUBLIC_GOOGLE_LOGIN_URL)
  }, [])

  return (
    <div className="flex h-screen w-screen justify-center bg-black text-center align-middle">
      <div className="absolute h-full w-full animate-[fadein_1s_ease-in-out_0s_1_forwards] bg-login bg-cover"></div>
      <div className="flex w-[756px] flex-col items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
        <Image src="/images/logo.svg" alt={'BATON'} width={99} height={41} />
        <div className="mt-6 flex h-[406px] w-[511px] flex-col rounded-2xl bg-white px-24 pt-20">
          <FadeTransition
            className="text-center text-[34px] font-extrabold leading-[46px] text-black"
            contents={['Welcome back', 'Magic link sent!']}
            index={fadTransitionIndex[currentStage]}
          >
            {title[currentStage]}
          </FadeTransition>
          <div
            className={twMerge(
              'mt-[13px] flex flex-col justify-end transition-[height] duration-1000 ease-in-out',
              currentStage === Stage.MAGIC_LINK_SUCCESS && 'h-10 ',
              currentStage != Stage.MAGIC_LINK_SUCCESS && 'h-24 transition-none'
            )}
          >
            {currentStage != Stage.MAGIC_LINK_SUCCESS && (
              <Input
                className="sm:test-sm mb-4 border-gray-200 text-center text-base"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                placeholder={isEmailInputFocused ? '' : 'name@ryder.com'}
                onFocus={() => {
                  setIsEmailInputFocused(true)
                }}
                onBlur={() => {
                  setIsEmailInputFocused(false)
                }}
              />
            )}
            <LoginButton
              className={twMerge(
                'rel bottom-0 text-white disabled:cursor-default disabled:hover:opacity-100 disabled:hover:shadow-none',
                currentStage === Stage.MAGIC_LINK_SUCCESS && 'bg-green-600',
                currentStage <= Stage.MAGIC_LINK_PENDING && 'bg-red-600'
              )}
              onClick={handleOnSendMagicLinkClick}
              disabled={currentStage >= Stage.MAGIC_LINK_PENDING}
            >
              {primaryButtonContent[currentStage]}
            </LoginButton>
          </div>

          {currentStage === Stage.INITIAL && (
            <>
              <div className="mt-[26px] text-center font-extrabold text-black">or</div>
              <LoginButton
                className="mt-[26px] w-full bg-black text-white"
                onClick={handleOnSignInWithGoogleClick}
              >
                <div className="flex items-center space-x-3">
                  <Image
                    src="/icons/google.svg"
                    alt="Google Icon"
                    width={20}
                    height={20}
                  />
                  <div>Sign in with Google</div>
                </div>
              </LoginButton>
            </>
          )}
          {currentStage === Stage.MAGIC_LINK_SUCCESS && (
            <div className="mt-[13px] flex h-[214px] w-[214px] flex-col justify-between self-center bg-truck bg-contain bg-center">
              <div className="mt-6 font-extrabold text-gray-400">
                Please check your inbox.
              </div>
              <div
                onClick={handleOnBackToLoginClick}
                className="mb-[18px] cursor-pointer text-link font-medium text-gray-400 underline"
              >
                Back to login
              </div>
            </div>
          )}
        </div>
        <div className="mt-8 text-white">
          <EnvelopeIcon className="inline" />
          <Link href="mailto:engineering@baton.io" className="ml-2">
            engineering@baton.io
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
